<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">

                <el-row style="display: flex;flex-wrap: wrap;" v-show="true">
                    <div class="flex a-center" style="margin-bottom:15px;margin-right:32px;">
                        
                        <el-input   placeholder="请输入内容" v-model.trim="searchValue" class="input-with-select">
                                <el-select style="width:120px;" v-model="searchType" slot="prepend" placeholder="请选择">
                                    <el-option  key="1" label="客户编号" :value="1"></el-option>
                                    <el-option  key="2" label="认购人姓名" :value="2"></el-option>
                                </el-select>
                          </el-input>
                    </div>
                    
                    <div class="flex a-center" style="margin-bottom:15px;"> 
                       
                        <span>票据日期：</span> 
                        <el-date-picker
                        v-model="searchStartTime"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                        </el-date-picker>
                        <div class="hw_ml5 hw_mr5">-</div>
                        <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="searchEndTime"
                        placeholder="选择日期">
                        </el-date-picker>
                        <el-button style="margin-left:32px;" type="primary" @click.stop="handleSubmit">搜索</el-button>
                        <el-button @click.stop="handleClear">清空</el-button>
                        <!-- <el-button @click.stop="godetail">测试</el-button> -->
                    </div>
                </el-row>
                <el-table :data="tableData" >
                    <el-table-column label="区域" prop="regionName" ></el-table-column>
                    <el-table-column label="客户编号" prop="cust_code"  ></el-table-column>
                    <el-table-column label="认购人姓名" prop="buyName" ></el-table-column>
                    <el-table-column label="EOI产品" prop="productName" ></el-table-column>
                    <el-table-column label="EOI金额" prop="contractMoney" ></el-table-column>
                    <el-table-column label="票据日期" prop="payTimeName" ></el-table-column>
                    <el-table-column label="回款金额" prop="money" >
                        <template slot-scope="scope">
                            <div >{{scope.row.money+" (澳币)"}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="缴费创建时间" prop="createTimeName" >
                         <template slot-scope="scope">
                            <div v-html="scope.row.createTimeName"></div>
                        </template>

                    </el-table-column>
                    <el-table-column label="操作"
                        fixed="right">
                        <template slot-scope="scope">
                            <el-button v-if="checkPay"
                                type="text"
                                @click.stop="handleUpdateState(scope.row)"
                            >确认到账</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
export default {
    name: 'checkPayMoneyList',
    components: {},
    props: {},
    data() {
        return {
            tableData: [],
            searchStartTime:'',
            searchEndTime:'',
            searchValue:'',
            searchType:1,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            checkPay:false
            
        };
    },
    created() {
        this.checkPay = this.$hasAuthFor("api/order/payOrderExamine");
        if(this.$route.params.searchStartTime!=''){
            this.searchStartTime = this.$route.params.searchStartTime
        }
        this.fetchData();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        async handleUpdateState(data) {
            let loadingRef;
            await this.$confirm("您确定该笔回款已到账吗？", "系统提示")
                const praData={"id":data.id,"examineStatus":1}
                console.log(praData)
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/order/payOrderExamine",
                    method: "post",
					data:praData
                })

				if (res.code === 2000) {
					this.$message({
                        message: "确认成功",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
                    this.fetchData()
				} else if (res.code !== 1003) 
					throw res;   
             
           
        },
        handleSubmit() {
            this.pageNum=1
            this.pageSize=10
            this.fetchData();
        },
        handleClear() {
            this.pageNum=1
            this.pageSize=10
            this.count = 0;
            this.searchType=1
            this.searchValue=''
            this.searchStartTime=''
            this.searchEndTime=''
            this.fetchData();
        },
        async fetchData() {
            const loading = this.$loading();
            try{
                const res = await this.$axios({
                    url: "/api/message/queryCheckPayMoney",
                    method: "post",
                    data: {
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        searchType:this.searchType,
                        searchValue:this.searchValue,
                        searchStartTime:this.searchStartTime,
                        searchEndTime:this.searchEndTime,
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    this.tableData = res.data.payMoneyList || [];
                    this.total = res.data.count || 0;
                }

            }catch(reason){
                this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});
				loading?.close();
            }

            
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
    },
};
</script>
<style lang="scss" scoped>

</style>